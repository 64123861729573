import React from "react";
//import "./App.css";
import { FaPhone, FaEnvelope, FaMap } from "react-icons/fa";
import { Typography, Box, makeStyles, Grid } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#e4943b",
    height: "auto",
    //marginTop: "-100px",
    //   display: flex;
    //   flex-wrap: wrap;
  },
  about: {
    //width: "300px",
    height: "auto",
    fontSize: 13,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
  contact: {
    fontSize: 13,
    [theme.breakpoints.down("sm")]: {
      fontSize: 9,
    },
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <Box className={classes.footer}>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <Typography className={classes.about} style={{ fontWeight: "bold" }}>
            About Us
          </Typography>
          <Typography className={classes.about}>
            If you're looking to learn swimming with quality coaching,
            individual attention, and a supportive learning environment, our
            classes are the perfect fit for you. We believe in excellence in our
            coaches, classes, and practices. We don't settle for what we have,
            but constantly strive for excellence
          </Typography>
        </Grid>

        <Grid item xs={5}>
          <Typography
            className={classes.contact}
            style={{ fontWeight: "bold" }}
          >
            Contact Us:
          </Typography>
          <Typography className={classes.contact}>
            <FaPhone /> (929)-300-2320
          </Typography>
          <Typography className={classes.contact}>
            <FaEnvelope /> FL-swimming@outlook.com
          </Typography>
          <Typography className={classes.contact}>
            <FaMap />
            711 Stewart Ave-LA Fitness, Garden City, NY
          </Typography>
        </Grid>
      </Grid>

      <Typography className={classes.about}>
        © 2023 FL Swim School. All Rights Reserved.
      </Typography>
    </Box>
  );
}
