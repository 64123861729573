import React from "react";
//import axios from "axios";
//import { Link } from "react-router-dom";
import {
  makeStyles,
  Card,
  CardContent,
  //CardMedia,
  Typography,
  CardHeader,
  Grid,
} from "@material-ui/core";
import "./App.css";

const useStyles = makeStyles({
  card: {
    background: "#f3f3f3",
    //maxWidth: 400,
    height: "auto",
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "10%",
    marginRight: "10%",
    //borderRadius: 5,
    //border: "5px solid #e4943b",
    //borderLeft: "50px",
    //boxShadow: "0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);",
    //padding: "0px",
    //padding: "5px",
    //paddingLeft: "70px",
    //paddingRight: "70px",
  },
  titleHead: {
    // color: "#0c3c5c",
    // background: "#e4943b",
    color: "#e4943b",
    background: "#0c3c5c",
    border: "5px solid #0c3c5c",
    //fontWeight: "bolder",
    textAlign: "center",
    //padding: "10px",
    //marginTop: "20px",
  },
  content: {
    color: "#0c3c5c",
    border: "5px solid #f3f3f3",
    padding: "10px",
    //marginBottom: "20px",
    height: "auto",
    fontSize: 20,
    //fontWeight: "bold",
  },
  grid: {
    flexGrow: 1,
    flexDirection: "row",
    //border: "10px",
    //margin: "10px",
    marginTop: "20px",
  },
  media: {
    height: "100%",
    width: "100%",
  },
  button: {
    color: "#1e8678",
    fontWeight: "bold",
    fontSize: 12,
  },
  headLine: {
    color: "#0c3c5c",
    textAlign: "center",
    fontSize: 30,
    fontWeight: "bold",
  },
  headContent: {
    color: "#0c3c5c",
    textAlign: "center",
    fontSize: 20,
    fontWeight: "bold",
  },
});

export default function Lessons() {
  const classes = useStyles();

  return (
    <div>
      <p className={classes.headLine}>Our Lessons</p>
      <p className={classes.headContent}>
        If you are interested in finding out which class would be better suited
        for your child, you can find detailed information about our classes
        below.
      </p>
      <Grid container className={classes.grid} spacing={12}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className={classes.card} variant="outlined">
            <CardHeader
              className={classes.titleHead}
              //   title="(1:1) Private Lessons"
              //   titleTypographyProps={{ variant: "h5", component: "h2" }}
              title={
                <Typography variant="h5" component="h2">
                  <b>(1:1) Private Lessons</b>
                </Typography>
              }
            />
            <CardContent>
              <Typography className={classes.content}>
                Private lessonsoffer individualized 1:1 instruction using a
                progressive curriculum tailored to meet the needs of each
                student. This program welcomes swimmers aged 4 and above, of all
                abilities.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className={classes.card} variant="outlined">
            <CardHeader
              className={classes.titleHead}
              title={
                <Typography variant="h5" component="h2">
                  <b>(1:2) Semi-Private Lessons</b>
                </Typography>
              }
            />
            <CardContent>
              <Typography className={classes.content}>
                Students are required to book with their semi-private class
                partner. Semi-private lessons are great for friends or siblings
                who want to learn together while receiving individualized
                attention.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className={classes.card} variant="outlined">
            <CardHeader
              className={classes.titleHead}
              title={
                <Typography variant="h5" component="h2">
                  <b>(1:4) Group Lessons</b>
                </Typography>
              }
            />
            <CardContent>
              <Typography className={classes.content}>
                We offer small group swim classes for swimmers of all levels,
                ranging from beginners to advanced. Our students are grouped
                with similar age and skill level, ensuring an environment that
                is safe, supportive, and conducive to learning.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Card className={classes.card} variant="outlined">
            <CardHeader
              className={classes.titleHead}
              title={
                <Typography variant="h5" component="h2">
                  <b>(1:6) Team</b>
                </Typography>
              }
            />
            <CardContent>
              <Typography className={classes.content}>
                Team is offered to students who have mastered 3 or more swimming
                strokes and want to improve their speed and techniques,
                providing individualized feedback and guidance to up to 6
                students in this lesson.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
}
