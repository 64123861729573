//import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import Home from "./Home";
import Lessons from "./Lessons";
import Pricing from "./Pricing";
import Footer from "./Footer";
import logo from "../img/logo.jpg";
// import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Grid,
  Toolbar,
  //Typography,
  useMediaQuery,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#e4943b",
  },
  logoBox: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  logo: {
    userSelect: "none",
    pointerEvents: "none",
    width: "300px",
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
    },
  },
  navLinkBox: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
  navLink: {
    marginLeft: "6px",
    padding: "0.4rem 0.8rem",
    color: "#e4943b",
    backgroundColor: "white",
    borderRadius: "3px",
    textDecoration: "none !important",
    whiteSpace: "nowrap",
    "&:hover": {
      backgroundColor: "transparent",
      color: "white",
      border: "2px solid white",
    },
  },
}));

function App() {
  const classes = useStyles();
  const isMdScreen = useMediaQuery("(min-width: 960px)");
  //const theme = useTheme();
  //const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  return (
    <BrowserRouter>
      <AppBar position="static" className={classes.header}>
        <Toolbar>
          <Grid container direction={isMdScreen ? "row" : "column"}>
            <Grid item xs={0} sm={0} md={4} lg={4} xl={4}></Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.logoBox}
            >
              <img className={classes.logo} src={logo} alt="Logo" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              className={classes.navLinkBox}
            >
              <NavLink className={classes.navLink} to="/">
                Home
              </NavLink>
              <NavLink className={classes.navLink} to="/lessons">
                Our Lessons
              </NavLink>
              <NavLink className={classes.navLink} to="/pricing">
                Our Pricing
              </NavLink>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* <Box className={classes.app}>
        <Box className={classes.header}>
          <Box className={classes.logoBox}>
            <img className={classes.logo} src={logo} alt="Logo" />
          </Box>

          <Box className={classes.navLinkBox}>
            <NavLink className={classes.navLink} to="/">
              Home
            </NavLink>
            <NavLink className={classes.navLink} to="/lessons">
              Our Lessons
            </NavLink>
            <NavLink className={classes.navLink} to="/pricing">
              Our Pricing
            </NavLink>
          </Box>
        </Box> */}
      {/* <header className="App-header">
          <div className="col-sm-4 header_place_holder">
            <p hidden>place holder</p>
          </div>
          <div className="col-sm-4 logo">
            <img style={{ maxHeight: "200px" }} alt="logo" src={logo} />
          </div>
          <div className="col-sm-4 bar">
            <NavLink className="nav-link" to="/">
              Home
            </NavLink>
            <NavLink className="nav-link" to="/lessons">
              Our Lessons
            </NavLink>
            <NavLink className="nav-link" to="/pricing">
              Our Pricing
            </NavLink>
          </div>

          <br />
        </header> */}

      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/lessons" element={<Lessons />}></Route>
        <Route path="/pricing" element={<Pricing />}></Route>
      </Routes>

      <footer>{<Footer />}</footer>
      {/* </Box> */}
    </BrowserRouter>
  );
}

export default App;
