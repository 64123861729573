import React from "react";
//import logo from "../img/logo.jpg";
import homePage from "../img/home.jpeg";
import mobileHome from "../img/mobileHome.jpeg";
//import mobileHome2 from "../img/mobileHome2.jpeg";
import { Box, useMediaQuery } from "@material-ui/core";

export default function Home() {
  const isMdScreen = useMediaQuery("(max-width: 960px)");
  return (
    <Box>
      {isMdScreen ? (
        <img
          src={mobileHome}
          alt="mobileHome"
          style={{
            width: "100vw",
            height: "auto",
            userSelect: "none",
            pointerEvents: "none",
            display: "block",
          }}
        />
      ) : (
        <img
          src={homePage}
          alt="homePage"
          style={{
            width: "100%",
            height: "auto",
            userSelect: "none",
            pointerEvents: "none",
          }}
        />
      )}
    </Box>
  );
}
